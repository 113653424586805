import React, {ChangeEvent, FormEvent, KeyboardEvent} from 'react';
import logo from './logo.png';
import './App.css';
import { padStart } from 'lodash';
import ResultTable from "./ResultTable";
import Confirmation from './Confirmation';

interface IProps {
    searchNum: string,
    result: object,
    error: string,
    buttonText: string,
    searchDisabled: boolean,
    canRetry: boolean,
}

interface IState {
    searchNum: string,
    result: any,
    error: string,
    buttonText: string,
    searchDisabled: boolean,
    canRetry: boolean,
    minStatement: string,
    maxStatement: string,
    confirmation: string,
    hasQueryParm: boolean,
}

class App extends React.Component<IProps, IState> {
    _configJson: any = '';

    constructor(props: IProps) {
        super(props);

        this.state = {
            searchNum: '',
            result: null,
            error: '',
            buttonText: 'Search',
            searchDisabled: false,
            canRetry: true,
            minStatement: '',
            maxStatement: '',
            confirmation: '',
            hasQueryParm: false,
        };

        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
        this.searchAgain = this.searchAgain.bind(this);
        this.fetchSearchResults = this.fetchSearchResults.bind(this);
        this.confirmResults = this.confirmResults.bind(this);
        this.catchError = this.catchError.bind(this);
        this.setError = this.setError.bind(this);
        this.clearConfirmation = this.clearConfirmation.bind(this);
    }

    componentDidMount() {
        fetch(`env-config.json`)
            .then(response => response.json())
            .then(result => this._configJson = result)
            .then(() => {if(this._configJson.ENV !== "PRD") {console.log(this._configJson);}})
            .catch(error => console.log("Oops! ", error.message))
            .finally(() => this.setState({minStatement: 'Minimum payment of $' + this._configJson.PAY_MIN}))
            .finally(() => this.setState({maxStatement: 'Maximum payment of $' + this._configJson.PAY_MAX}));

        if(document.URL.includes("?")) {
            this.setState({hasQueryParm: true});

            let start = document.location.search.indexOf("?") + 1;
            let end = document.location.search.indexOf("=") - 1;
            let type = document.location.search.substr(start, end);
            let message = decodeURIComponent(document.location.search.substr(end + 2));

            switch (type) {
                case 'confirm':
                    this.setState({confirmation: message});
                    break;
                case 'error':
                    this.setState({error: message});
                    break;
                case 'oops':
                    this.setState({error: 'This service experienced an unexpected result'});
                    break;
                default:
                    this.setState({error: ''});
            }
        }
    }

    clearConfirmation() {
        this.setState({confirmation: '', error: ''});
    }

    onSearchKeyPress(event: KeyboardEvent) {
        const charCode = event.charCode;

        if((charCode < 48 || charCode > 57) && charCode !== 8 && charCode !== 13) {
            event.preventDefault();
        }
    }

    onSearchChange(event: ChangeEvent) {
        const docRegEx = new RegExp("^[0-9]*$");

        this.clearConfirmation();

        if(!docRegEx.test((event.target as HTMLTextAreaElement).value)) {
            (event.target as HTMLTextAreaElement).value = this.state.searchNum;
        } else {
            this.setState({searchNum: (event.target as HTMLTextAreaElement).value});
        }
    }

    onSearchSubmit(event: FormEvent) {
        event.preventDefault();

        this.clearConfirmation();

        let { searchNum } = this.state;
        searchNum = padStart(searchNum, 6, "0");

        this.setState({buttonText: 'Searching...', searchDisabled: true});

        this.fetchSearchResults(searchNum)
    }

    searchAgain() {
        this.setState({ result: false });
    }

    fetchSearchResults(searchNum: string) {
        const url = `${this._configJson.API_URL}${searchNum}`;

        fetch(url)
            .then(response => response.json())
            .then(result => this.setState({ result }, () => console.log(result)))
            .then(() => this.confirmResults(this.state.result.docNum.trim()))
            .then(() => this.setState({error: ""}))
            .then(() => this.setState({canRetry: true}))
            .catch(error => this.catchError(error, searchNum))
            .finally(() => this.setState({buttonText: 'Search', searchDisabled: false}));
    }

    confirmResults(quickCheck: string) {
        if(quickCheck === "Not found") {
            this.setState({error: "Unable to find an offender with the DOC Number entered"});
            this.setState({result: null});
            throw new Error("Unable to find an offender with the DOC Number entered");
        } else if(quickCheck.substr(0,5) === "Error") {
            this.setState({result: null});
            throw new Error(quickCheck);
        }
    }

    catchError(error: Error, searchNum: string) {
        let { canRetry } = this.state;

        if(canRetry) {
            this.setState({canRetry: false});
            console.log("Retrying");
            this.fetchSearchResults(searchNum);
        } else {
            console.log("Oops! ", error.message);
            this.setError();
            this.setState({canRetry: true});
        }
    }

    setError() {
        if(this.state.error === "") {
            this.setState({error: "Sorry, it appears this service is currently unavailable"});
        }
    }

    render() {
        const {
            result,
            error,
            searchDisabled,
            buttonText,
            confirmation,
            minStatement,
            maxStatement,
            hasQueryParm,
        } = this.state;

        return (
            <div className={"container"}>
                <div>
                    <header>
                        <img src={logo} className="App-logo" alt="logo" />
                        <div className="Department">Department of Corrections</div>
                        <div className="AppTitle">Offender Payments to DCC</div>
                    </header>
                </div>
                <div>
                    { confirmation && hasQueryParm
                        ? <Confirmation type='confirm' message={confirmation} />
                        : <div />
                    }
                    { error && hasQueryParm
                        ? <Confirmation type='error' message={error} />
                        : <div />
                    }
                    { result
                        ? <ResultTable data={result}
                                       turl={this._configJson.TOKEN_URL}
                                       surl={this._configJson.SCRIPT_URL}
                                       sfp={this._configJson.SFP_BSN}
                                       coo={this._configJson.COO_BSN}
                                       gps={this._configJson.GPS_BSN}
                                       ica={this._configJson.ICA_BSN}
                                       max={this._configJson.PAY_MAX}
                                       min={this._configJson.PAY_MIN}
                                       sfpKey={this._configJson.SFP_KEY}
                                       cooKey={this._configJson.COO_KEY}
                                       gpsKey={this._configJson.GPS_KEY}
                                       icaKey={this._configJson.ICA_KEY}
                                       searchAgain={this.searchAgain}
                        />
                        : <form id="SearchForm" onSubmit={this.onSearchSubmit}>
                            DOC Number:
                            {searchDisabled
                                ? <input type="text"
                                         maxLength={6}
                                         onKeyPress={this.onSearchKeyPress}
                                         onChange={this.onSearchChange}
                                         disabled
                                         required
                                />
                                : <input type="text"
                                         maxLength={6}
                                         onKeyPress={this.onSearchKeyPress}
                                         onChange={this.onSearchChange}
                                         required
                                />
                            }
                            <div>
                                <br/>
                                {searchDisabled
                                    ? <button className="btn btn-primary" disabled>
                                        {buttonText}
                                    </button>
                                    : <button className="btn btn-primary">
                                        {buttonText}
                                    </button>
                                }
                            </div>
                        </form>
                    }
                </div>
                {
                    error !== '' && hasQueryParm === false &&
                    <div className={"red-background"}>{error}</div>
                }
                <div className={"fine-print"}>
                    <hr/>
                    <div>{minStatement}</div>
                    <div>{maxStatement}</div>
                    <div>Contact supervising agent if any other amount.</div>
                    <div className={"fee-disclaimer"}>** No processing fee will be charged at this time **</div>
                </div>
            </div>
        );
    }
}

export default App;
